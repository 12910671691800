.content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h1 {
    font-weight: 200;
    text-transform: uppercase;
    font-size: 40px;
  }
  h2 {
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 0.16em;
  }
  .icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    margin-bottom: 20px;
    transition: all 0.5s;
    &:hover {
      color: gray;
      transform: scale(1.1);
    }
  }
  .linkedin {
    color: #0a66c2;
  }
  .contacts {
    position: relative;
    display: flex;
    div {
      margin-right: 10px;
    }
    .copyText {
      position: absolute;
      font-size: 12px;
      top: -20px;
      left: 285px;
      display: none;
      color: rgb(68, 68, 68);
      width: 100px;
    }
    .copyIcon:hover + .copyText {
      display: block;
    }
  }
}
.copyIcon {
  cursor: pointer;
  margin-left: 3px;
  &:hover {
    color: grey;
    transform: scale(1.2);
  }
}
img {
  width: 310px;
  height: 419px;
}

@media only screen and (max-width: 900px) {
  .info {
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 30px;
      letter-spacing: 0.14em;
    }
    .icon {
      width: 30px;
      height: 30px;
    }
  }
}
img {
  width: 248px;
  height: 335px;
}

@media only screen and (max-width: 656px) {
  .content {
    display: block;
    margin-bottom: 40px;
  }
  .info {
    .header {
      h2 {
        font-size: 24px;
        margin: 0 0 30px 0;
        letter-spacing: 0.14em;
      }
    }
    .icon {
      margin-bottom: 0;
    }
  }
  img {
    width: 124px;
    height: 167px;
  }
}

@media only screen and (max-width: 280px) {
  .info {
    .contacts {
      display: block;
    }
  }
}
