body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
}
a {
  color: #000;
  text-decoration: none;
}
li {
  list-style: none;
}

h2 {
  font-weight: 400;
  font-size: 26px;
  margin: 40px 0;
}

section {
  padding-left: 79px;
  padding-right: 79px;
}

@media only screen and (max-width: 900px) {
  section {
    padding-left: 39px;
    padding-right: 39px;
  }
}
@media only screen and (max-width: 360px) {
  section {
    padding-left: 19px;
    padding-right: 19px;
  }
}
