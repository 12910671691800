.container {
  width: 100%;
  max-width: 1240px;
  background-color: rgb(245, 245, 245);
  margin: 20px auto;
  padding: 0 0 79px 0;
  .cv {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding: 20px 79px 20px;
    a {
      margin-left: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      .cvIcon {
        width: 18px;
        height: 18px;
        color: rgb(51, 51, 51);
      }
      &:hover {
        transform: scale(1.2);
        .cvIcon {
          color: grey;
        }
      }
    }
    .cvText {
      position: absolute;
      font-size: 12px;
      color: rgb(68, 68, 68);
      top: 0px;
      right: 80px;
      display: none;
    }
  }
  a:hover + .cvText {
    display: block;
  }
}

@media only screen and (max-width: 1240px) {
  .container {
    margin: 0;
  }
}
