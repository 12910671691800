.content {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.title {
  li {
    padding: 15px 10px;
    transition: background-color 0.3s;
    cursor: pointer;
    &:hover {
      background-color: rgb(250, 250, 250);
    }
  }
  .active {
    background-color: #fff;
    font-weight: bold;
    cursor: default;
    &:hover {
      background-color: #fff;
    }
  }
}

.describtion {
  padding-top: 10px;
  padding-left: 20px;
  background-color: #fff;
  li {
    padding-bottom: 10px;
    font-size: 14px;
    span {
      margin-right: 5px;
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 360px) {
  .title {
    li {
      font-size: 12px;
    }
  }

  .describtion {
    padding-left: 10px;
    li {
      padding-bottom: 10px;
      font-size: 12px;
      span {
        margin-right: 2px;
      }
    }
  }
}
