.container {
  h2 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.works {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.work {
  background-color: rgb(250, 250, 250);
  position: relative;
  height: 200px;
  transition: transform 0.5s;

  .blur {
    backdrop-filter: blur(5px);
  }
  &:hover {
    transform: scale(1.2);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0;
    transition: opacity 0.5s;
    > * {
      transform: translateY(20px);
      transition: transform 0.5s;
    }

    &:hover {
      opacity: 1;
      > * {
        transform: translateY(0);
      }
    }
    h4 {
      font-weight: 400;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .links {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      a {
        color: #fff;
        border: 1px solid grey;
        border-radius: 10px;
        padding: 2px 10px;
        margin: 5px 0;
        transition: background-color 0.5s;
        &:hover {
          background-color: grey;
        }
      }
    }
  }
}
.icon {
  margin-left: 3px;
}
.movies {
  width: 220px;
  .movies_imgs {
    display: flex;
    justify-content: space-between;
    img {
      width: 95px;
    }
  }
}
.more {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  margin-right: 20px;
  .moreIcon {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 1154px) {
  .works_container {
    display: flex;
    justify-content: center;
    .works {
      justify-content: flex-start;
      width: 700px;
    }
    .work {
      margin: 20px 40px;
    }
    .more {
      margin-left: 20px;
    }
  }
}

@media only screen and (max-width: 961px) {
  .works_container {
    .works {
      justify-content: center;
    }
  }
  .work {
    background-color: #f5f5f5;
    padding-bottom: 100px;
    &:hover {
      transform: scale(1);
    }
    .no_overlay {
      h4 {
        font-weight: 500;
        font-size: 20px;
      }
      .links {
        display: flex;
        justify-content: center;
        a {
          border: 1px solid grey;
          border-radius: 10px;
          padding: 2px 10px;
          margin: 10px;
          transition: background-color 0.5s;
          &:hover {
            background-color: #fff;
          }
        }
      }
    }
  }
  .work.movies {
    .no_overlay {
      position: absolute;
      width: 290px;
      left: -25px;
      margin-top: 10px;
    }
  }
  .work.more {
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
}

@media only screen and (max-width: 754px) {
  .work {
    width: 100%;
    padding-bottom: 70px;
    &:hover {
      transform: scale(1);
    }
    .no_overlay {
      text-align: center;
      h4 {
        font-size: 16px;
      }
    }
  }
  .work.movies {
    .movies_imgs {
      width: 200px;
      margin: 0 auto;
    }
    .no_overlay {
      width: 100%;
      left: 0;
    }
  }
  .work.more {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 399px) {
  .work {
    .no_overlay {
      h4 {
        font-size: 14px;
      }
      .links {
        a {
          font-size: 12px;
          margin: 8px;
        }
      }
    }
  }
}
