.container {
  h2 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
.skills {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  div {
    position: relative;
    width: 90px;
    .skillName {
      display: none;
      text-align: center;
      position: absolute;
      width: 100px;
      top: -60px;
      right: 0;
      background-color: rgb(255, 255, 255);
      border-radius: 10px;
      padding: 10px 10px;
    }
    .skillName::after {
      content: "";
      position: absolute;
      top: 36px;
      left: 50px;
      width: 18px;
      height: 18px;
      background-color: #fff;
      transform: rotate(45deg);
    }
    .htmlLogo {
      color: #f06529;
    }
    .cssLogo {
      color: #109dd9;
    }
    .reactLogo {
      color: #61dbfb;
    }
    .nodeLogo {
      color: #3c873a;
    }
    .mongoLogo {
      color: #589636;
    }
    .expressLogo {
      color: #444;
    }
    .reactNativeLogo {
      color: #61dafb;
    }
    .npmLogo {
      color: #cb3837;
    }
    .gitLogo {
      color: #f14e32;
    }
    .JsLogo {
      color: #f7df1e;
    }
    .TsLogo {
      color: #3178c6;
    }
    .icon {
      width: 55px;
      height: 55px;
      transition: all 0.5s;
      &:hover {
        color: grey;
        transform: scale(1.1);
      }
    }
    .icon:hover + .skillName {
      display: block;
    }
  }
}
